<template>
  <div class="page">
    <div
      v-if="data.status !== 'Solved'"
      class="row m-0 mb-1 justify-content-end"
    >
      <!-- <b-button
        variant="primary"
        @click="isShowCancelDialog = true"
      >
        Update priority
      </b-button> -->
      <b-button
        v-b-modal.close-ticket-modal
        variant="outline-danger"
        class="ml-2"
      >
        <!-- @click="isShowCancelDialog = true" -->
        Close this ticket
      </b-button>
    </div>
    <b-card
      text-variant="white"
      bg-variant="primary"
      class="position-static"
    >
      <b-card-title class="text-white flex-justify-between">
        <span>{{ data.title }}</span><span>Ticket #: {{ data.support_ticket_id }}</span>
      </b-card-title>
      <p>Last Update: {{ formatDate(data.updated_at) }}</p>
      <p>Created: {{ formatDate(data.created_at) }}</p>
      <b-badge
        class="mt-1 p-1 text-capitalize"
        pill
        :variant="getVariant(data.status)"
      >
        {{ data.status }}
      </b-badge>

      <b-badge
        class="mt-1 ml-1 p-1 text-capitalize"
        pill
        :variant="getVariant(data.priority)"
      >
        {{ data.priority }}
      </b-badge>
    </b-card>
    <b-card
      v-for="(thread, index) in data.support_ticket_comments"
      :key="index"
      :border-variant="isAdmin(thread)"
    >
      <b-card-body>
        <b-card-title>
          <b-avatar
            size="md"
            :src="thread.user.profile_image"
            class="mr-1"
          />
          {{ thread.user.name }}</b-card-title>
        <b-card-text>
          {{ thread.comment }}
        </b-card-text>
      </b-card-body>
      <b-card-footer>
        <small class="text-muted">Updated: {{ formatDate(thread.created_at) }}</small>
      </b-card-footer>
    </b-card>
    <b-card>
      <validation-observer
        id="commentForm"
        ref="simpleRules"
        #default="{invalid}"
      >
        <b-form
          class="auth-forgot-password-form mt-2"
          @submit.prevent="validationForm"
        >
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Message"
              rules="required"
            >
              <b-form-textarea
                v-model="message"
                placeholder="Message"
                rows="8"
              />
              <p
                class="text-danger"
                style="margin-top:5px;"
              >
                {{ errors[0] }}
              </p>
            </validation-provider>
          </b-form-group>
          <b-button
            class="mt-1"
            variant="primary"
            type="submit"
            :disabled="invalid"
          >
            Submit
          </b-button>
        </b-form>
      </validation-observer>
    </b-card>

    <b-modal
      id="close-ticket-modal"
      title="Closing the ticket"
      centered
      @ok="onDialogOK"
    >
      <p>
        Are you sure?
      </p>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BCardTitle, BBadge, BCardText, BCardBody, BCardFooter, BFormTextarea, BButton, BAvatar, BForm, BFormGroup, BModal,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BCard,
    BCardTitle,
    BBadge,
    BCardText,
    BCardBody,
    BCardFooter,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BAvatar,
    BForm,
    BFormGroup,
    BModal,
  },
  data() {
    return {
      required,
      message: '',
      data: {},
      priorities: [{ name: 'High' }, { name: 'Moderate' }, { name: 'Low' }],
      isShowCancelDialog: false,
    }
  },
  created() {
    this.$store.dispatch('superadmin/getTicket', this.$route.params.id).then(response => {
      if (response.code === 200) {
        this.data = response.data
        // const container = document.querySelector('#commentForm')
        // console.log(container)
        // container.scrollTop = container.scrollHeight
      }
    }).catch(err => {
      console.error(err)
    })
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const data = {
            id: this.$route.params.id,
            message: this.message,
          }

          this.$store.dispatch('superadmin/addComment', data).then(response => {
            console.log(response)
            if (response.code === 200) {
              this.data.support_ticket_comments.push(response.data)
              this.message = ''
              this.data.status = 'In Progress'
            }
          }).catch(err => {
            console.error(err)
          })
        }
      })
    },
    formatDate(date) {
      return new Date(date).toLocaleString('en-CA')
    },
    isAdmin(thread) {
      if (thread.user.memberType === 'superadmin') {
        return 'primary'
      }
      return null
    },
    getVariant(status) {
      switch (status) {
        case 'Active': return 'primary'
        case 'Solved': return 'success'
        case 'Open': return 'primary'
        case 'Closed': return 'danger'
        case 'moderate': return 'warning'
        case 'In Progress': return 'warning'
        default: return 'dark'
      }
    },
    onDialogOK() {
      this.$store.dispatch('superadmin/closeTicket', this.$route.params.id).then(response => {
        if (response.code === 200) {
          this.$router.push({ name: 'tickets' })
        }
      }).catch(err => {
        console.error(err)
      })
      this.isShowCancelDialog = false
    },
  },
}
</script>

<style>

</style>
